import React, { ComponentType } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { ILogger, LoggerSymbol, SiteFeatureConfigSymbol, ViewerModel, ViewerModelSym } from '@wix/thunderbolt-symbols'
import type { OOIComponentLoader } from '../types'
import { name } from '../symbols'
import { MODULE_URL } from '../constants'
import { reportError } from '@wix/thunderbolt-commons'
import LazySentry from '../lazySentry'
import { Props } from '../tpaWidgetNativeFactory/tpaWidgetNative'

async function loadRequireJS(moduleRepoUrl: string = MODULE_URL, logger: ILogger) {
	// since react umd bundles do not define named modules, we must load react before loading requirejs.
	// further details here: https://requirejs.org/docs/errors.html#mismatch
	// requirejs will be hopefully removed once ooi comps will be consumed as comp libraries.
	await window.reactAndReactDOMLoaded
	await new Promise((resolve, reject) => {
		const script = document.createElement('script')
		script.src = `${moduleRepoUrl}/requirejs-bolt@2.3.6/requirejs.min.js`
		script.onload = resolve
		script.onerror = reject
		document.head.appendChild(script)
	})
	// @ts-ignore
	window.define('lodash', [], () => _)
	// @ts-ignore
	window.define('reactDOM', [], () => ReactDOM)
	// @ts-ignore
	window.define('react', [], () => React)

	// @ts-ignore
	window.requirejs.onError = (error) => {
		const { requireModules, requireType } = error
		logger.captureError(error, {
			tags: { feature: 'ooi', methodName: 'requirejs.onError' },
			extra: { requireModules, requireType },
		})
	}
}

// eslint-disable-next-line prettier/prettier
export default withDependencies([named(SiteFeatureConfigSymbol, name), ViewerModelSym, LoggerSymbol],({ ooiComponentsData }, { siteAssets }: ViewerModel, logger: ILogger): OOIComponentLoader => {
		let waitForRequireJsToLoad: Promise<unknown> | null = null

		const load = <T>(url: string): Promise<T> =>
			new Promise(async (resolve, reject) => {
				waitForRequireJsToLoad =
					waitForRequireJsToLoad || loadRequireJS(siteAssets.clientTopology.moduleRepoUrl, logger)
				await waitForRequireJsToLoad
				__non_webpack_require__([url], (module: any) => resolve(module), reject)
			})

		return {
			async getComponent(widgetId: string) {
				const { componentUrl, sentryDsn } = ooiComponentsData[widgetId]
				const ReactComponent = await load<ComponentType<Props>>(componentUrl).then(
					(module: any) => module?.default?.component
				)
				if (!ReactComponent) {
					reportError(new Error('component is not exported'), LazySentry, sentryDsn)
				}
				await window.externalsRegistry.react.loaded // wait for React to load since it is loaded dynamically
				const { ooiReactComponentClientWrapper } = require('../tpaWidgetNativeFactory/tpaWidgetNativeClient')
				return ooiReactComponentClientWrapper(ReactComponent)
			},
		}
	}
)
